<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      size="xl"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <h3>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="text-body"
            />
            Thông tin chung
          </h3>
          <b-row>
            <b-col
              lg="4"
            >
              <b-form-group>
                <label>Mã đơn vị<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required"
                  :custom-messages="{
                    required: 'Mã đơn vị là bắt buộc',
                  }"
                >
                  <b-form-input
                    v-model="dataInput.code"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              lg="4"
            >
              <b-form-group>
                <label>Tên đơn vị cấp phòng lao động thương binh xã hội<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="fullName"
                  rules="required"
                  :custom-messages="{
                    required: 'Tên  đơn vị cấp phòng LĐTBXH là bắt buộc',
                  }"
                >
                  <b-form-input
                    v-model="dataInput.fullName"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              lg="4"
            >
              <b-form-group>
                <label for="code">Kiểu người dùng<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="userTypeId"
                  rules="required"
                  :custom-messages="{required: 'Kiểu người dùng là bắt buộc'}"
                >
                  <v-select
                    v-model="dataInput.userTypeId"
                    :reduce="item => item.id"
                    label="name"
                    :options="arrayUserType || []"
                    placeholder="Lựa chọn kiểu người dùng"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="4"
            >
              <b-form-group>
                <label>Tỉnh/Thành phố<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="provinceId"
                  rules="required"
                  :custom-messages="{required: 'Tỉnh/Thành phố là bắt buộc'}"
                >
                  <v-select
                    v-model="dataInput.provinceId"
                    :reduce="item => item.id"
                    label="name"
                    :options="arrayProvinces || []"
                    placeholder="Lựa chọn tỉnh/thành phố"
                    @input="getComboboxDistrict"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              lg="4"
            >
              <b-form-group>
                <label>Quận/Huyện<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="districtId"
                  rules="required"
                  :custom-messages="{required: 'Quận/Huyện là bắt buộc'}"
                >
                  <v-select
                    v-model="dataInput.districtId"
                    :reduce="item => item.id"
                    label="name"
                    :options="arrayDistrict || []"
                    placeholder="Lựa chọn quận/huyện"
                    @input="getComboboxWard"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="4"
            >
              <b-form-group>
                <label for="code">Địa chỉ</label>
                <b-form-input
                  v-model="dataInput.address"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col
              lg="4"
            >
              <b-form-group>
                <label for="code">Xã/Phường<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="wardId"
                  rules="required"
                  :custom-messages="{required: 'Xã phường là bắt buộc'}"
                >
                  <v-select
                    v-model="dataInput.wardId"
                    :reduce="item => item.id"
                    label="name"
                    :options="arrayWards || []"
                    placeholder="Lựa chọn xã/phường"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-row>
            <b-col
              lg="4"
            >
              <b-form-group>
                <label>Email<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                  :custom-messages="{required: 'email là bắt buộc', email: 'email sai định dạng'}"
                >
                  <b-form-input
                    v-model="dataInput.email"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              lg="4"
            >
              <b-form-group>
                <label>Số điện thoại</label>
                <b-form-input
                  v-model="dataInput.phoneNumber"
                />
              </b-form-group>
            </b-col>

            <b-col
              lg="4"
            >
              <b-form-group>
                <label for="code">Fax </label>
                <b-form-input
                  v-model="dataInput.fax"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="4"
            >
              <b-form-group>
                <label for="code">Địa chỉ</label>
                <b-form-input
                  v-model="dataInput.address"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-if="type==='add'"
          >
            <h3>
              <feather-icon
                icon="LogInIcon"
                size="18"
                class="text-body"
              />
              Thông tin đăng nhập
            </h3>
            <b-row>
              <b-col
                lg="4"
              >
                <b-form-group>
                  <label>Tên đăng nhập<span class="required">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules="required|special_characters"
                    :custom-messages="{
                      required: 'Tên đăng nhập là bắt buộc',
                    }"
                  >
                    <b-form-input
                      v-model="dataInput.userName"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                lg="4"
              >
                <b-form-group>
                  <label>Mật khẩu<span class="required">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required|special_characters"
                    :custom-messages="{
                      required: 'Mật khẩu là bắt buộc',
                    }"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="password"
                        v-model="dataInput.password"
                        :type="passwordFieldType"
                        autocomplete="new-password"
                        :state="errors.length > 0 ? false:null"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          size="16"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      required,
      dataInput: {
        code: '',
        address: '',
        districtId: null,
        email: '',
        fax: '',
        fullName: '',
        password: '',
        phoneNumber: '',
        provinceId: null,
        userName: '',
        userTypeId: '',
        wardId: 0,
      },
      arrayUserType: [],
      arrayProvinces: [],
      arrayDistrict: [],
      arrayWards: [],
    }
  },
  watch: {
    async dataDetail() {
      if (this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetail.code,
          address: this.dataDetail.address,
          districtId: this.dataDetail.districtId,
          email: this.dataDetail.email,
          fax: this.dataDetail.fax,
          fullName: this.dataDetail.fullName,
          password: this.dataDetail.password,
          phoneNumber: this.dataDetail.phoneNumber,
          provinceId: this.dataDetail.provinceId,
          userName: this.dataDetail.userName,
          userTypeId: this.dataDetail.userTypeId,
          wardId: this.dataDetail.wardId,
        }
        this.arrayDistrict = await this.fecthDistricts(this.dataDetail.provinceId)
        this.arrayWards = await this.fecthWards(this.dataDetail.districtId)
      }
    },
  },
  async created() {
    this.getComboboxUserType()
    this.arrayProvinces = await this.fecthProvinces()
  },
  methods: {
    ...mapActions('business', ['fecthProvinces', 'fecthDistricts', 'fecthWards']),
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.arrayDistrict = []
      this.arrayWards = []
      this.dataInput = {
        code: '',
        address: '',
        districtId: null,
        email: '',
        fax: '',
        fullName: '',
        password: '',
        phoneNumber: '',
        provinceId: null,
        userName: '',
        userTypeId: '',
        wardId: 0,
      }
    },
    async getComboboxUserType() {
      const { data } = await axiosApiInstance.get(ConstantsApi.USER_TYPE)
      this.arrayUserType = data?.data
    },
    async getComboboxDistrict(id) {
      this.dataInput.districtId = null
      this.dataInput.wardId = null
      this.arrayDistrict = await this.fecthDistricts(id)
    },
    async getComboboxWard(id) {
      this.dataInput.wardId = 0
      this.arrayWards = await this.fecthWards(id)
    },
  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
